<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.tipo_documento.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :titulo-botao-novo="$t('modulos.tipo_documento.titulos.novo')"
      :sem-botao-ok="!buscarPermissao('TipoDocumento', 'Inserir')"
      :sem-filtro="!buscarPermissao('TipoDocumento', 'Listar')"
      @abrirNovo="abrirNovo"
      @filtrar="filtrar"
    >
      <template v-slot:filtro="{ filtro }">
        <filtro :filtro="filtro" />
      </template>
    </cabecalho-pagina>
    <botao-padrao
      v-if="podeExcluir && buscarPermissao('TipoDocumento', 'Deletar')"
      class="my-2"
      outlined
      color="danger"
      @click="excluirRegistros"
    >
      <v-icon>$mdiTrashCanOutline</v-icon>
      {{ $t('geral.botoes.excluir') }}
    </botao-padrao>
    <tabela-padrao

      v-model="tabela.selecionados"
      :dados="tabela.dados"
      class="mt-2"
      :colunas="tabela.colunas"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :mostrar-checkbox="buscarPermissao('TipoDocumento', 'Deletar')"
      @paginar="listarRegistros"
    >
      <!-- eslint-disable vue/valid-v-slot-->
      <template v-slot:item.validade="{item}">
        <div class="alinhar-direita">
          {{ item.item.validade }}
        </div>
      </template>

      <template v-slot:item.acoes="{ item }">
        <dropdown-padrao
          v-if="buscarPermissao('TipoDocumento', 'Editar')"
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <div>
            <dropdown-padrao-item

              text
              color="secondary"
              @click="abrirEditar(item.item)"
            >
              {{ $t('geral.botoes.editar') }}
            </dropdown-padrao-item>
          </div>
        </dropdown-padrao>
      </template>
    </tabela-padrao>

  </div>
</template>
<script>
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import TipoDocumentoService from '@common/services/cadastros/TipoDocumentoService';
import EnumeradorService from "@common/services/cadastros/EnumeradorService";
import Filtro from "./filtro.vue";
import helpers from '@common/utils/helpers';
export default {
  components: {
    CabecalhoPagina,
    Filtro,
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'acoes',
            text: '',
          },
          {
            value: 'nome',
            text: this.$t('modulos.tipo_documento.tabela.nome'),
            sortable: true,
          },
          {
            value: 'descricao',
            text: this.$t('modulos.tipo_documento.tabela.descricao'),
            sortable: true,
          },
          {
            value: 'validade',
            text: this.$t('modulos.tipo_documento.tabela.validade'),
            sortable: true,
          },
          {
            value: 'nomeArquivo',
            text: this.$t('modulos.tipo_documento.tabela.anexo'),
            sortable: true,
          },
          {
            value: 'classificacaoDocumento',
            text: this.$t('modulos.tipo_documento.tabela.classificacaoDocumento'),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
      enumClassificacaoDocumento: [],

    };
  },
  computed: {
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
  },
  mounted() {
    this.listarRegistros();
    this.$store.dispatch('Layout/alterarTituloPagina', this.$t('modulos.tipo_documento.titulos.listagem'));

  },
  created() {
    helpers.redirecionarSemPermissao(this, 'TipoDOcumento', 'Listar');
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(this, funcionalidade, funcionalidadeAcao);
    },
    filtrar: function (filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },
    listarRegistros: function (paginaAtual = 1, porPagina = 10) {
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        ...this.filtroAplicado,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      TipoDocumentoService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;

      EnumeradorService.buscar("EnumClassificacaoDocumento")
        .then((res) => { this.enumClassificacaoDocumento = res

           this.tabela.dados.map(item => {
            this.enumClassificacaoDocumento.forEach(v => {
              if(v.seq == item.classificacaoDocumento) return item.classificacaoDocumento = v.text
              })
            })
          })

          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    excluirRegistros: function () {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');

        this.tabela.selecionados.forEach(item => {
          TipoDocumentoService.excluir(item.id)
          .then(() => {
            this.listarRegistros();
            this.toastSucesso(this.$t(`modulos.tipo_documento.exclusao_sucesso`));
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
            this.tabela.selecionados = []
          });
        })
      });
    },
    abrirNovo: function () {
      this.$router.push({ name: 'tipo-documento-novo' });
    },
    abrirEditar: function (item) {
      this.$router.push({
        name: 'tipo-documento-editar',
        params: { id: item.id },
      });
    },

  },
};
</script>
<style lang="scss">
.alinhar-direita{
  display: flex;
  justify-content: right;
}
</style>

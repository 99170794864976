<template>
  <div class="row">
    <input-text
      v-model="filtro.nome"
      class="col-12"
      :label="$t('modulos.tipo_documento.filtro.nome')"
      em-filtro
    />
    <input-select
      v-model="filtro.classificacaoDocumento "
      class="col-12"
      :label="$t('modulos.tipo_documento.formulario.classificacaoDocumento')"
      :placeholder="$t('geral.inputs.selecione')"
      :options="opcoes.classificacaoDocumento "
      retornar-objeto
      em-filtro
    />
  </div>
</template>
<script>
import EnumeradorService from "@common/services/cadastros/EnumeradorService";
export default {
  props: {
    filtro: {},
  },
  data() {
    return {
      opcoes: {
        classificacaoDocumento: []
      },
    };
  },
  mounted() {
    this.listarClassificacaoDocumento();
  },
  methods: {
    listarClassificacaoDocumento : function () {
      this.$store.dispatch("Layout/iniciarCarregamento");
      EnumeradorService.buscar("EnumClassificacaoDocumento")
        .then((res) => {
          this.opcoes.classificacaoDocumento  = res;
        })
        .finally(() => {
          this.$store.dispatch("Layout/terminarCarregamento");
        });
    },
  }
};
</script>

